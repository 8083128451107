import React, { useState } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import { graphql } from "gatsby";
import Cookies from "js-cookie";

import Layout from "../components/layout";
import Breadcrumb from "../components/Breadcrumb";
import { handleLogin, loginIsEnabled, mutationLogin } from "../services/auth";
import { isBrowser } from "../helpers";

export default function Login({ data }) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(false);

  function handleSubmit(e) {
    if (e) e.preventDefault();

    if (!isBrowser) return false;

    if (!login || !password) {
      setError("You must enter your email and password.");
      return false;
    }

    setProcessing(true);

    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        mutationLogin({ login, password }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.errors) {
          const { message } = res.data.errors[0];
          // console.log(res)
          if (
            message === "incorrect_password" ||
            message === "invalid_username" ||
            message === "invalid_email"
          ) {
            setError("Incorrect login details");
            setProcessing(false);
          }

          return null;
        }

        setError("");
        setProcessing(false);

        const { firstName = "", lastName = "" } = res.data.data.login.user;

        handleLogin({
          login,
          firstName,
          lastName,
          id: res.data.data.login.user.id,
          group: res.data.data.login.user.userGroup?.group.slug,
          token: res.data.data.login.authToken,
          refreshToken: res.data.data.login.refreshToken,
        });

        try {
          if (window.OptanonActiveGroups.search("C0001") !== -1) {
            Cookies.set(
              "user_id",
              JSON.stringify(res.data.data.login.user.id),
              {
                expires: 7,
              }
            );
          }
        } catch (ReferenceError) {}

        navigate("/account");
      });
  }

  return (
    <Layout
      meta={{
        ...data.wpPage.Meta,
        canonical: "/login/",
      }}
      path={"/login/"}
      cta={"a"}
      themeOptions={data.wp.themeOptions}
    >
      <Breadcrumb
        type="page"
        current={{
          title: "Login",
          slug: "login",
          uri: "/login",
        }}
      />
      {loginIsEnabled() ? (
        <form
          onSubmit={handleSubmit}
          style={{
            backgroundColor: "#1b365d",
            margin: "50px auto",
            maxWidth: 768,
            padding: 32,
          }}
        >
          <h3
            style={{
              color: "white",
              fontWeight: "normal",
              letterSpacing: "0.016em",
              fontSize: 32,
              marginTop: 0,
              marginBottom: 12,
            }}
          >
            Login to your account
          </h3>
          <p
            style={{
              color: "white",
            }}
          >
            Please complete your login details below to view your secure area:
          </p>
          <label
            htmlFor="email"
            style={{
              color: "white",
              fontSize: 12,
              lineHeight: 1.6,
              letterSpacing: "0.0152em",
              textTransform: "uppercase",
              margin: 0,
              fontFamily: "Overpass",
            }}
          >
            Email Address
          </label>
          <input
            name="email"
            type="email"
            placeholder="Email address"
            onChange={(e) => setLogin(e.target.value)}
            style={{
              fontFamily: "Overpass",
              backgroundColor: "transparent",
              borderRadius: 20,
              fontSize: 12,
              lineHeight: 2,
              padding: "7px 20px",
              border: "1px solid white",
              marginBottom: 24,
              color: "white",
              outline: "none",
              display: "block",
              width: "100%",
            }}
          />
          <label
            htmlFor="password"
            style={{
              color: "white",
              fontSize: 12,
              lineHeight: 1.6,
              letterSpacing: "0.0152em",
              textTransform: "uppercase",
              margin: 0,
              fontFamily: "Overpass",
            }}
          >
            Password
          </label>
          <input
            name="password"
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            style={{
              fontFamily: "Overpass",
              backgroundColor: "transparent",
              borderRadius: 20,
              fontSize: 12,
              lineHeight: 2,
              padding: "7px 20px",
              border: "1px solid white",
              marginBottom: 24,
              color: "white",
              outline: "none",
              display: "block",
              width: "100%",
            }}
          />
          {processing && (
            <div
              className="logging-in"
              style={{
                color: "white",
                fontFamily: "Overpass",
                letterSpacing: "0.006em",
                marginBottom: 16,
              }}
            >
              Processing
            </div>
          )}
          {!!error && (
            <div
              style={{
                marginBottom: 16,
                display: "block",
                backgroundColor: "#c8102e",
                color: "white",
                fontFamily: "Overpass",
                padding: "12px 24px",
                fontSize: 14,
                letterSpacing: "0.012em",
                borderRadius: 2,
              }}
            >
              {error}
            </div>
          )}
          <input
            type="submit"
            value="Submit"
            style={{
              width: "auto",
              backgroundColor: "#c8102e",
              color: "white",
              fontFamily: "Bebas Neue",
              fontSize: 16,
              display: "block",
              padding: "14px 40px",
              letterSpacing: "0.016em",
              textAlign: "center",
              backgroundPosition: "center right 24px",
              backgroundRepeat: "no-repeat",
              outline: "none",
              boxShadow: "0 8px 10px rgb(0 0 0 0.8)",
              cursor: "pointer",
              borderRadius: 32,
              border: "none",
            }}
          />
          <p
            style={{
              color: "white",
            }}
          >
            If you have forgotten your password or require any further help
            logging in, please contact{" "}
            <a
              href="mailto:sales@workshop-pro.co.uk"
              style={{
                color: "white",
              }}
            >
              sales@workshop-pro.co.uk
            </a>
          </p>
          {/* <a href="/" style={{
        color: 'white',
        marginTop: 12,
        display: 'table',
        opacity: .75,
        fontSize: 12,
      }}>Forgot your password?</a> */}
        </form>
      ) : (
        <div
          style={{
            backgroundColor: "#1b365d",
            margin: "50px auto",
            maxWidth: 768,
            padding: 32,
          }}
        >
          <h3
            style={{
              color: "white",
              fontWeight: "normal",
              letterSpacing: "0.016em",
              fontSize: 32,
              marginTop: 0,
              marginBottom: 12,
            }}
          >
            Login unavailable
          </h3>
          <p
            style={{
              color: "white",
            }}
          >
            Our member's area is unavailable for the time being while we do some
            important maintenance. Please check back later.
          </p>
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;
